import React, { Component } from 'react';
import { PieChart, Pie } from 'recharts';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class Demographics extends Component {
  constructor() {
    super();
    const today = moment().subtract(1, 'day').format('YYYY-MM-DD');
    const start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    this.state = { start, end, today, data: {}, loading: false, initalLoad: true };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.renderBrackets = this.renderBrackets.bind(this);
    this.renderPlans = this.renderPlans.bind(this);
    this.renderCountries = this.renderCountries.bind(this);
    this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this);
  }

  componentDidMount() {
    document.title = 'Demographics | Flamingo Insights Platform';
  }

  // Networking

  loadData() {
    this.setState({ loading: true, initalLoad: false });
    const { start, end } = this.state;
    return api.get(`/demographics/users?start=${start}&end=${end}`)
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  // Handlers

  handleStartChange(e) {
    this.setState({ start: e.target.value });
  }

  handleEndChange(e) {
    this.setState({ end: e.target.value });
  }

  // Renders

  renderBrackets(data) {
    const maxTotal = Math.max.apply(Math, Object.values(data.map((bracket) => bracket.total)));
    return (
      <>
        <p className="fm-demographics-title">Age Breakdown</p>
        <div className="fm-demographics-brackets">
          {
            data.map((bracket, i) => {
              return (
                <div className="fm-demographics-brackets-item" key={i}>
                  <div className="fm-demographics-brackets-bar-holder">
                    <div className="fm-demographics-brackets-bar" title={bracket.total || 0} style={{ height: ((((bracket.total || 0) / maxTotal) * 100) + '%') }}></div>
                  </div>
                  <p className="fm-demographics-brackets-value">{ bracket.total || 0 }</p>
                  <p className="fm-demographics-brackets-date">{ bracket.label }</p>
                </div>
              );
            })
          }
        </div>
      </>
    );
  }

  renderPlans(data) {
    const pieData = data.map((plan) => ({ name: plan.plan, value: plan.total }));
    return (
      <div className="fm-demographics-graph">
        <p className="fm-demographics-title">Plans</p>
        <PieChart width={425} height={300}>
          <Pie dataKey="value" data={pieData} cx={212} cy={150} innerRadius={40} outerRadius={80} label={(d) => this.renderCustomizedLabel(d, pieData)} fill="#FF206E" />
        </PieChart>
      </div>
    );
  }

  renderCountries(data) {
    const pieData = data.map((country) => ({ name: country.country, value: country.total }));
    return (
      <div className="fm-demographics-graph">
        <p className="fm-demographics-title">International Riders</p>
        <PieChart width={425} height={300}>
          <Pie dataKey="value" data={pieData} cx={212} cy={150} innerRadius={40} outerRadius={80} label={(d) => this.renderCustomizedLabel(d, pieData)} fill="#FF206E" />
        </PieChart>
      </div>
    );
  }

  renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }, data) {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const item = data[index];

    return (
      <text x={x} y={y} fill="#222" textAnchor={x > cx ? "start" : "end"} fontSize={12} dominantBaseline="central">
        { item.name } ({ (percent * 100).toFixed() }%)
      </text>
    );
  }

  render() {
    const { loading, data, start, end, today, error, initalLoad } = this.state;
    return (
      <div className="fm-demographics">
        <NavigationBar title="Demographics" loading={loading} />
        <div className="fm-demographics-form">
          <p className="fm-demographics-form-label">From</p>
          <input value={start} className="fm-input" type="date" onChange={this.handleStartChange} max={today} />
          <p className="fm-demographics-form-label">To</p>
          <input value={end} className="fm-input" type="date" onChange={this.handleEndChange} max={today} />
          <button className="fm-demographics-button" onClick={this.loadData}>{ loading ? '...' : 'Go' }</button>
        </div>
        { data.ageBrackets && this.renderBrackets(data.ageBrackets) }
        <div className="fm-demographics-section">
          { data.plans && this.renderPlans(data.plans) }
          { data.countries && this.renderCountries(data.countries) }
          { initalLoad && <p className="fm-demographics-empty">Select a period above.</p> }
          { loading && <p className="fm-demographics-empty">Loading data...</p> }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Demographics;
