import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Loading from 'components/common/loading';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import api from 'helpers/api';

class Dashboard extends Component {
  constructor() {
    super();
    this.state = { data: {}, loading: true };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Dashboard | Flamingo Insights Platform';
    this.loadData();
  }

  // Networking

  loadData() {
    return api.get(`/dashboard`)
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  // Renders

  render() {
    const { loading, data, error } = this.state;

    return (
      <div className="fm-dashboard">
        <NavigationBar title="Dashboard" loading={loading} />
        <p className="fm-dashboard-welcome">Welcome to the Flamingo Insights Platform. { !loading && `Statistics for ${ moment().format('dddd, Do MMMM YYYY') }.` }</p>
        <div className="fm-dashboard-bar">
          {
            loading ? (
              <div className="fm-dashboard-bar-loading">
                <Loading />
              </div>
            ) : (
              <>
                <div className="fm-dashboard-bar-item">
                  <Icon className="fm-dashboard-bar-icon" icon="route" />
                  <div className="fm-dashboard-bar-text">
                    <p className="fm-dashboard-bar-value">{ data.tripCount || 0 }</p>
                    <p className="fm-dashboard-bar-label">Total Trips</p>
                  </div>
                </div>
                <div className="fm-dashboard-bar-item">
                  <Icon className="fm-dashboard-bar-icon" icon="stopwatch" />
                  <div className="fm-dashboard-bar-text">
                    <p className="fm-dashboard-bar-value">{ data.averageDuration || 0 } m</p>
                    <p className="fm-dashboard-bar-label">Average Duration</p>
                  </div>
                </div>
                <div className="fm-dashboard-bar-item">
                  <Icon className="fm-dashboard-bar-icon" icon="ruler-horizontal" />
                  <div className="fm-dashboard-bar-text">
                    <p className="fm-dashboard-bar-value">{ data.averageDistance || 0 } km</p>
                    <p className="fm-dashboard-bar-label">Average Distance</p>
                  </div>
                </div>
                <div className="fm-dashboard-bar-item">
                  <Icon className="fm-dashboard-bar-icon" icon="arrow-circle-right" />
                  <div className="fm-dashboard-bar-text">
                    <p className="fm-dashboard-bar-value">{ data.onTripVehicles || 0 }</p>
                    <p className="fm-dashboard-bar-label">On Trip Vehicles</p>
                  </div>
                </div>
                <div className="fm-dashboard-bar-item">
                  <Icon className="fm-dashboard-bar-icon" icon="map-marked-alt" />
                  <div className="fm-dashboard-bar-text">
                    <p className="fm-dashboard-bar-value">{ data.availableVehicles || 0 }</p>
                    <p className="fm-dashboard-bar-label">Available Vehicles</p>
                  </div>
                </div>
                {
                  /*
                  <div className="fm-dashboard-bar-item">
                    <Icon className="fm-dashboard-bar-icon" icon="road" />
                    <div className="fm-dashboard-bar-text">
                      <p className="fm-dashboard-bar-value">{ data.deployedVehicles || 0 }</p>
                      <p className="fm-dashboard-bar-label">Deployed Vehicles</p>
                    </div>
                  </div>
                  */
                }
              </>
            )
          }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Dashboard;
