import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Map, { Polylines, Dots } from 'components/common/map';
import api from 'helpers/api';
import user from 'helpers/user';

const typeOptions = [{ label: 'Trip start locations', value: 'trip-starts' }, { label: 'Trip routes', value: 'trip-routes' }, { label: 'Trip end locations', value: 'trip-ends' }, { label: 'Deployment locations', value: 'deployments' }, { label: 'Event locations', value: 'changes' }];
const vehicleOptions = [{ label: 'on all vehicles', value: 'all' }, { label: 'on bikes', value: 'bike' }, { label: 'on scooters', value: 'scooter' }];

class Maps extends Component {
  constructor() {
    super();
    const maxDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    const fortnight = moment().subtract(14, 'day').format('YYYY-MM-DD');
    this.state = { region: false, data: [], type: 'trip-routes', dataType: 'trip-routes', vehicle: 'all', start: fortnight, end: maxDate, maxDate, loading: true, firstLoad: true };

    this.loadData = this.loadData.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Maps | Flamingo Insights Platform';
    this.loadLocation();
  }

  // Networking

  loadLocation() {
    user.getCurrentUser().then((user) => this.setState({ region: user.insights.region, loading: false }));
  }

  loadData() {
    this.setState({ loading: true, data: [] });
    const { start, end, type, vehicle } = this.state;
    const dataType = type;
    let endpoint = `/maps/${type}?start=${start}&end=${end}`;

    if (vehicle !== 'all') {
      endpoint += `&type=${vehicle}`;
    }

    api.get(endpoint)
      .then((res) => this.setState({ data: res.data.data, loading: false, firstLoad: false, dataType }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  // Handlers

  handleStartChange(e) {
    this.setState({ start: e.target.value });
  }

  handleEndChange(e) {
    this.setState({ end: e.target.value });
  }

  handlePeriodChange(e) {
    this.setState({ period: e.target.value });
  }

  handleTypeChange(e) {
    this.setState({ type: e.target.value });
  }

  handleVehicleChange(e) {
    this.setState({ vehicle: e.target.value });
  }

  // Renders

  renderMap() {
    const { region, data, dataType } = this.state;
    const mapOptions = { center: { lat: region.centreLatitude, lng: region.centreLongitude }, zoom: 14 };
    return (
      <Map options={mapOptions} google={window.google} isGlobal hideLocation>
        {
          dataType === 'trip-routes' ? (
            <Polylines encodedPaths={data} strokeOpacity={0.05} strokeColor={'#ff206e'} />
          ) : (
            <Dots dots={data} />
          )
        }
      </Map>
    );
  }

  render() {
    const { loading, error, type, vehicle, start, end, maxDate, firstLoad } = this.state;

    return (
      <div className="fm-maps">
        <NavigationBar title="Maps" loading={loading} />
        <div className="fm-maps-content">
          { !firstLoad && this.renderMap() }
          { (!loading && firstLoad) && <p className="fm-maps-message">Select a category, start and end date, then press go.</p> }
          { (loading && firstLoad) && <p className="fm-maps-message">Loading...</p> }
        </div>
        <div className="fm-maps-options">
          <select className="fm-input fm-input-select" value={type} onChange={this.handleTypeChange}>
            { typeOptions.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
          </select>
          <select className="fm-input fm-input-select" value={vehicle} onChange={this.handleVehicleChange}>
            { vehicleOptions.map((vehicle, i) => <option key={i} value={vehicle.value}>{vehicle.label}</option>) }
          </select>
          <input value={start} className="fm-input" type="date" onChange={this.handleStartChange} max={maxDate} />
          <input value={end} className="fm-input" type="date" onChange={this.handleEndChange} max={maxDate} />
          <button className="fm-maps-options-button" disabled={loading} onClick={this.loadData}>{ loading ? '...' : 'Go' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Maps;
